<template>
    <div id="app">
        <el-container class="body">
            <el-header>
                <header_nav class="header"></header_nav>
            </el-header>
            <el-main class="main">
                <router-view></router-view>
            </el-main>

            <el-footer class="footer" height="auto">
                <footer_about></footer_about>
            </el-footer>
        </el-container>
        <el-alert class="cookieBox" v-if="$store.getters.cookieBoxPopup === true" type="info" show-icon>
            <h3>Cookies</h3>
            <span>
                <pre>{{ t('Dear users, this website uses cookies to provide you with a better website experience. Continuing to browse means you agree to the cookie terms.') }}</pre>
            </span>
            <router-link class="nav-link" :to="{ name: 'privacy_policy' }" replace>{{ t('Cookie policy') }}</router-link>
        </el-alert>
    </div>
</template>

<script>
import header_nav from './components/header_nav.vue';
import footer_about from './components/footer_about.vue';
import { useI18n } from 'vue-i18n';
//import price_strategy from './components/price/price.vue';
//import music_gen from './components/music_gen.vue';
//import music_describe from './components/music_describe.vue';
export default {
    name: 'App',
    components: { footer_about, header_nav },
    data() {
        return {
            backgroundUrl: require('@/assets/background.png')
        };
    },
    setup() {
        const { t } = useI18n();

        return { t };
    },
    mounted() {
        // 组件挂载后，监听页面滚动事件
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 组件销毁前，移除滚动事件监听器
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.$store.dispatch('cookieBoxPopup');
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
};
</script>

<style lang="scss">
:root {
    --bule-deep: #1e2a78;
    --bule-deep-bg: #f0f0f0;
    --bule-light: #146aff;
    /*--bule-deep-bg: #f0e6ff; 浅紫*/
    /*--bule-deep-bg: #dbe8ff; 浅蓝*/
    --gray: #9e9e9e;
    /*--black: #616161;*/
    --black: #1c2526;
    --yellow: #c8ff15;
    --yellow-op: #c8ff1570;
    --border-radius: 8px;

    --margin-app-width: 8vw;
}

body {
    color: white;
    font-family: 'Inter', sans-serif !important;
    /*background-image: url('@/assets/background.png');*/
    background-size: cover;
    background-position: center;
}

button {
    font-family: 'Inter', sans-serif !important;
}


.body {
    min-height: 100vh;
    /* 首页 */
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    top: 0;
    right: 0;
    left: 0;
    width: auto;
    height: auto;

    background: #040c18;
    background-image: url('./assets/background.png');
    background-size: cover; /* 使背景图片充满整个页面 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
}
.footer {
    /* Auto layout */
    display: flex;
    flex-direction: column;

    width: 100%;
    height: auto;
    left: 0;
    right: 0;
    bottom: 0;
    background: #162045;

    /* Inside auto layout */
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
}

.cookieBox {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 1000;

    pre {
        white-space: normal !important;
    }
}

/* 移动端 */
@media (max-width: 1000px) {
    .footer {
        height: auto !important;
    }

    .cookieBox {
        padding: 8px;
        z-index: 1000;

        h3 {
            margin: 0 !important;
        }

        pre {
            white-space: normal !important;
            margin: 8px 0 !important;
        }

        a {
            color: var(--bule-deep) !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.body {
    overflow: hidden !important;

    .el-header {
        height: 80px;
        padding: 0 var(--margin-app-width);
        width: 100%;
        border-bottom: #5940fe solid 2px;
    }

    .main {
        padding: 0 var(--margin-app-width);
        width: 100%;
        order: 2;
        margin-top: 3rem;
        overflow-x: hidden !important;
    }

    /* 移动端 */
    @media (max-width: 1000px) {
        .el-header {
            height: 64px;
            margin-top: 0;
            padding: 0 2vw;
        }
        .main {
            margin-top: 2rem;
        }
    }
}
</style>
