<template>
    <div class="song-container">
        <div class="song_list">
            <div class="ctlFrame">
                <el-button class="backButton" type="primary" @click="returnBack">
                    <el-icon>
                        <Back />
                    </el-icon>
                    <span>{{ t('Return Back') }}</span>
                </el-button>
            </div>
            <div v-for="item in songItem.clips" :key="item.id + item.audio_url + item.video_url + item.status">
                <music_play_control :song_clip="item" :song_title="item.title" :song_create_time="songItem.createTime"></music_play_control>
            </div>
        </div>

        <div class="song_lyrics">
            <h1 class="song_lyrics_title" v-if="sharemode">{{ t('Created by') }} {{ songItem.creator }}</h1>

            <router-link v-if="sharemode" class="song_gen_link" :to="{ path: '/body_main' }">{{ t('Generate Your Song') }} >>></router-link>
            <router-link v-else class="song_gen_link" :to="{ path: '/body_main' }">{{ t('Generate New Song') }} >>></router-link>
            <div class="music_style">
                <h1 class="style_title">{{ t('Music Style') }}</h1>
                <span class="style_text"> {{ songItem.metadata.tags }} </span>
            </div>
            <div style="height: 32px" />
            <div class="lyrics_area">
                <h1 class="lyrics_title">{{ t('Lyrics') }}</h1>
                <span class="lyrics_text">
                    {{ songItem.metadata.prompt }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'; // 引入defineAsyncComponent
import { apiSongTaskQuery } from '@/api/service';
import { useI18n } from 'vue-i18n';
import { Back } from '@element-plus/icons-vue';

const music_play_control = defineAsyncComponent(() => import('./music_play_control.vue'));

export default {
    name: 'music_play',
    components: { music_play_control, Back },
    props: ['song', 'sharemode'],
    data() {
        return {
            songItem: {
                clips: [
                    { audio_url: '', video_url: '' },
                    { audio_url: '', video_url: '' }
                ],
                title: '',
                creator: null,
                createTime: null,
                status: null,
                id: null,
                metadata: {
                    tags: '',
                    prompt: '',
                    type: null
                }
            },
            statusIntervalId: null
        };
    },
    setup() {
        const { t } = useI18n();

        return { t };
    },
    created() {
        console.log('music_play created110701 ', this.song, ' sharemode:', this.sharemode);
        this.songItem = JSON.parse(this.song);
        if (this.sharemode) {
            return;
        } else {
            this.fetchSongsLatestStatus();

            if (this.songItem.status !== 'complete') {
                this.statusIntervalId = setInterval(this.fetchSongsLatestStatus, 5000);
            }
        }
    },
    unmounted() {
        if (this.statusIntervalId !== null) {
            clearInterval(this.statusIntervalId);
            this.statusIntervalId = null;
        }
    },
    methods: {
        returnBack() {
            this.$router.push({ name: 'my_music' });
        },
        fetchSongsLatestStatus() {
            var songIds = [];
            if (this.songItem.status === 'complete') {
                if (this.statusIntervalId !== null) {
                    clearInterval(this.statusIntervalId);
                    this.statusIntervalId = null;
                }
                return;
            }
            songIds.push(this.songItem.id);
            var queryRequest = {
                songIds: songIds,
                seqId: new Date().getTime()
            };
            try {
                apiSongTaskQuery(queryRequest).then((res) => {
                    console.log('fetchSongsLatestStatus queryRequest:', queryRequest, ' res:', res);
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            if (res.data.data.mapSongGenerateInfo !== null && res.data.data.mapSongGenerateInfo !== undefined) {
                                var latestGenerateInfo = res.data.data.mapSongGenerateInfo[this.songItem.id];
                                if (latestGenerateInfo !== null) {
                                    this.songItem.clips = latestGenerateInfo.clips;
                                    if (latestGenerateInfo.status === 'complete') {
                                        var intervalCancel = true;
                                        for (var i = 0; i < this.songItem.clips.length; ++i) {
                                            if (this.songItem.clips[i].video_url === undefined || this.songItem.clips[i].video_url === null) {
                                                intervalCancel = false;
                                                break;
                                            }
                                        }
                                        if (intervalCancel) {
                                            clearInterval(this.statusIntervalId);
                                            this.statusIntervalId = null;
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        console.log('fetchSongsLatestStatus queryRequest:', queryRequest, ' failed:', res);
                    }
                });
            } catch (error) {
                console.log('fetchSongsLatestStatus queryRequest:', queryRequest, ' get exception:', error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ctlFrame {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-left: -60px;
    width: 100%;
}

.backButton {
    position: relative;
    display: flex;
    flex-direction: row;

    font-size: 20px; /* 设置图标大小 */
    background: transparent !important;
    border: none;
    margin-left: -16px;
}

.backButton:hover {
    color: #b6ff52;
}

.music-player {
    display: flex;
    flex-direction: row; /* Change from column to row */
    justify-content: left;
    align-items: flex-start; /* Align items at the top of the row */
    max-width: 100%; /* Allow it to stretch full width */
    overflow-x: auto; /* Add horizontal scrolling if needed */
}

.song-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    align-items: flex-start;
    padding: 0;
    gap: 96px;
    margin-bottom: 64px;
    width: 1128px;
    height: auto;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.song_list {
    /* Frame 40213 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 260px;
    height: auto;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.song_lyrics {
    /* Frame 40250 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: auto;
    height: auto;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.song_lyrics_head {
    /* Paragraph container */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: auto;
    height: auto;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.song_lyrics_title {
    /* Hi, Welcome */

    width: auto;
    height: auto;

    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    /* identical to box height */

    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.song_gen_link {
    width: auto;
    height: 34px;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    /* identical to box height */
    text-transform: capitalize;

    color: #c8ff15;
}

.music_style {
    /* Frame 40248 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: auto;
    height: auto;
}

.style_title {
    /* music style */

    width: auto;
    height: 39px;

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    text-transform: capitalize;

    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.style_text {
    font-size: 20px;
    color: var(--gray);
}

.lyrics_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //gap: 16px;
}

.lyrics_title {
    /* Lyrics */

    width: auto;
    height: auto;

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    /* identical to box height */

    text-transform: capitalize;

    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.lyrics_text {
    font-style: normal;
    font-size: 20px;

    line-height: 25px;
    //color: #c8ff15;
    color: var(--gray);
    font-weight: 600;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    white-space: pre-wrap; /* 保留空格和换行，同时允许自动换行 */
    //width: 600px;
}

/* 移动端 */
@media (max-width: 1000px) {
    .song-container {
        width: 100%;
        flex-direction: column;
        gap: 3rem;
    }
    .song_list {
        margin: 0 auto !important;
    }
    .lyrics_text {
        width: 100% !important;
    }
}
</style>
