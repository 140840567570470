import { createWebHistory, createRouter } from 'vue-router'
import nav_tab from '@/components/tab.vue';
import { defineAsyncComponent } from 'vue';
import axios from 'axios';

const asyncMyMusic = defineAsyncComponent(() =>
  import('@/components/my_music.vue')
);
const asynclogin = defineAsyncComponent(() =>
  import('@/components/login.vue')
);
const asyncBodyMain = defineAsyncComponent(() =>
  import('@/components/body_main.vue')
);
const asyncPrice = defineAsyncComponent(() =>
  import('@/components/price/price.vue')
);
const asyncMusicPlay = defineAsyncComponent(() =>
  import('@/components/music_play.vue')
);

const asyncSubscription = defineAsyncComponent(() =>
  import('@/components/subscription.vue')
);

const asyncTermOfService = defineAsyncComponent(() =>
  import('@/components/term_of_service.vue')
);

const asyncPrivacyPolicy = defineAsyncComponent(() =>
  import('@/components/privacy_policy.vue')
);

const asyncShareView = defineAsyncComponent(() =>
  import('@/components/share_view.vue')
);

const asyncAfterPayment = defineAsyncComponent(() =>
  import('@/components/price/after_payment.vue')
);

const asyncAuthGoogle = defineAsyncComponent(() =>
  import('@/components/callback/auth_google.vue')
);



const routes = [
    {
      path: '/',
      redirect: '/body_main'
    },
    {
      path: '/nav_tab',
      name: 'nav_tab',
      component: nav_tab
  },
     {
      path: '/my_music',
      name: 'my_music',
      
       component: asyncMyMusic,
       meta: {
          requireAuth: true  
      }
    },
    {
      path: '/login_account',
      name: 'login_account',
      props: route => ({ loginEmail: route.query.email, accessToken : route.query.accessToken }),
      component: asynclogin
  },
    {
      path: '/body_main',
      name: 'body_main',
      component: asyncBodyMain
  }
     ,
    {
      path: '/price_plan',
      name: 'price_plan',
      component: asyncPrice
  } ,
    {
      path: '/music_play',
      name: 'music_play',
      props: route => ({ song: route.query.song, sharemode : route.query.sharemode }),
      component: asyncMusicPlay
    
  } ,
    {
      path: '/my_subscription',
      name: 'my_subscription',
      component: asyncSubscription,
       meta: {
          requireAuth: true  
      }
  },
    {
      path: '/after_payment',
      name: 'after_payment',
      props: route => ({ reference_id : route.query.reference_id }),
      component: asyncAfterPayment
  },{
      path: '/term_of_service',
      name: 'term_of_service',
      component: asyncTermOfService
  },{
      path: '/privacy_policy',
      name: 'privacy_policy',
      component: asyncPrivacyPolicy
  }
    ,{
      path: '/share_view',
      name: 'share_view',
      props: route => ({ clipid: route.query.clipid }),
      component: asyncShareView
  },{
      path: '/auth_google',
      name: 'auth_google',
      component: asyncAuthGoogle
  }
  ]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
  console.log("beforeEach from:", from, " to:", to, " next:", next);
  const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (to.meta.requireAuth && !isAuthenticated) {
      router.push({name : 'login_account'})
    } else {
      next();	
    }
		
});

router.afterEach((to, from) => {
  console.log("afterEach from:", from, " to:", to);
});

router.beforeResolve((to, from, next) => {
  console.log("beforeResolve from:", from, " to:", to, " next:", next);
  
		next();	
});


// 创建 axios 实例
// 添加请求拦截器
axios.interceptors.response.use(response => {
  // 如果响应码是200，直接返回数据
     console.log("axios.interceptors.response: ", response);
    const code = response.data.code;
    // 检查状态码，如果不是200，跳转到登录页
    if (code === 501) {
      // 清理token或其他用户信息
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('email');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      // 跳转到登录页
      router.push('/login_account');
    }


  return response;
},
  error => {
    // 如果响应状态码不为 200，进行处理
    console.log("axios.interceptors.error: ", error);
   
    return Promise.reject(error); // 抛出错误，继续让调用者处理);
  }
);


export default router;