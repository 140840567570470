<template>
    <div>
        <nav class="navbar">
            <div class="logo">
                <img src="logo.png" alt="Product Logo" />
            </div>
            <ul class="nav-list">
                <li v-for="(tab, index) in tabs" :key="tab" :class="{ active: activeTab === index }" @click="scrollToSection(index)">
                    {{ tab }}
                </li>
            </ul>
            <div class="user-info">
                <button class="user-button" v-if="!isLoggedIn" @click="hanleUserLogin"></button>
                <button class="user-button" v-if="isLoggedIn" @click="closeUserInfo">useremail</button>
            </div>
        </nav>

        <div v-if="showUserInfo" class="user-info-popup">
            <div class="user-info-content">
                <p>用户信息:</p>
                <p>用户名: {{ username }}</p>
                <button @click="logout">退出</button>
            </div>
        </div>

        <div class="sections">
            <section :ref="(el) => (sections[0] = el)">
                <music_gen></music_gen>
            </section>
            <br />
            <section :ref="(el) => (sections[1] = el)">
                <music_play :songs="songs"></music_play>
            </section>
            <br />
            <section :ref="(el) => (sections[2] = el)">
                <price_strategy></price_strategy>
            </section>
            <br />

            <section :ref="(el) => (sections[3] = el)"></section>
        </div>
    </div>
    <!-- <div class="tab">
        <el-tabs class="nav-tabs" v-model="nav_to_tab">
            <el-tab-pane class="oneTab" label="AI Music Generation" name="music_gen_localtion"></el-tab-pane>
            <el-tab-pane class="oneTab" label="Pricing" name="price_localtion"></el-tab-pane>
        </el-tabs>
    </div> -->
</template>

<script>
import music_gen from './music_gen.vue';
/*import { ElTabPane, ElTabs } from 'element-plus';*/
import price_strategy from './price/price.vue';
import music_play from './music_play.vue';
import { ref, onMounted, onUnmounted } from 'vue';

import { useRouter } from 'vue-router';

export default {
    components: { music_gen, price_strategy, music_play },

    name: 'nav_tab',

    setup() {
        const router = useRouter();
        const tabs = ['AI Music Generator', 'Pricing', 'My music'];
        const sections = ref([]);
        const activeTab = ref(0);
        const isLoggedIn = ref(false); // 模拟用户登录状态
        const username = ref('mio'); // 用户名示例
        const useremail = ref('tmqsoft@gamil.com'); // 用户名示例
        const showUserInfo = ref(false);

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = sections.value.findIndex((section) => section === entry.target);
                    if (index !== -1) {
                        activeTab.value = index;
                    }
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, {
            threshold: 0.5
        });

        onMounted(() => {
            sections.value.forEach((section) => {
                observer.observe(section);
            });
        });

        onUnmounted(() => {
            observer.disconnect();
        });

        const scrollToSection = (index) => {
            console.log('scrollToSection:' + index);
            console.log('sessions:' + sections.value[index]);
            sections.value[index].scrollIntoView({ behavior: 'smooth' });
        };

        const hanleUserLogin = () => {
            if (!isLoggedIn.value) {
                // 如果未登录，跳转到登录页面
                console.log(this);
                router.push({ name: 'login_account' });
            }
        };

        const closeUserInfo = () => {
            showUserInfo.value = !showUserInfo.value;
        };

        const logout = () => {
            isLoggedIn.value = false;
            showUserInfo.value = false;
            // 这里可以添加更多注销逻辑
        };

        return {
            tabs,
            sections,
            activeTab,
            scrollToSection,
            isLoggedIn,
            username,
            useremail,
            showUserInfo,
            hanleUserLogin,
            closeUserInfo,
            logout,
            songs: [
                {
                    title: '',
                    artist: '',
                    cover: 'https://img0.baidu.com/it/u=438965791,584145342&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500',
                    file: 'https://www.ihaoge.net/server/1/143849264.mp3',
                    duration: 300
                },
                {
                    title: '',
                    artist: '',
                    cover: 'https://img0.baidu.com/it/u=3489882453,1564848997&fm=253&fmt=auto&app=120&f=JPEG?w=783&h=500',
                    file: 'song2.mp3',
                    duration: 300
                }
            ]
        };
    }
    /*
    data() {
        return {
            nav_to_tab: null
        };
    },
    handleTabClick() {
        // 根据点击的选项卡值来滚动到相应的卡片位置
        switch (this.nav_to_tab) {
            case 'music_gen_localtion':
                this.scrollToCard('music_gen_localtion');
                break;
            case 'price_localtion':
                this.scrollToCard('price_localtion');
                break;
        }
    },
    scrollToCard(refName) {
        // 使用 Vue 的 $refs 来获取卡片元素，并滚动到其位置
        const cardElement = this.$refs[refName];
        if (cardElement) {
            // 使用原生的 scrollIntoView 方法来滚动到元素位置
            cardElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }*/
};
</script>

<style>
.navbar {
    position: sticky;
    top: 0;
    background-color: #333;
    border-bottom: 1px solid #ccc;
    z-index: 1000;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; /* Add padding for spacing */
}

.nav-left {
    display: flex;
    align-items: center;
}

.logo img {
    height: 40px; /* Logo height */
    margin-right: 20px; /* Add margin to separate logo from tabs */
}

.nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.nav-list li {
    margin: 0 10px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    color: white;
}

.nav-list li.active::after {
    content: '';
    display: block;
    height: 3px;
    background-color: #007bff;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-button {
    background-color: #007bff;
    border: none;
    border-radius: var(--border-radius);
    color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}

.user-info-popup {
    position: fixed;
    top: 70px;
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px;
    z-index: 1001;
}

.sections {
    position: relative;
}

.user-info-content {
    text-align: left;
}
</style>
