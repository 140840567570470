// src/store/index.js
import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    user: localStorage.getItem('user'), // 用于存储用户信息
    rights: localStorage.getItem('rights'), // 用于存储用户信息
    subscription: localStorage.getItem('subscription'),
    email: localStorage.getItem('email'),
    isAuthenticated: localStorage.getItem('isAuthenticated'), // 用于存储用户是否已登录
    token: localStorage.getItem('token'),
    cookieBoxPopup: localStorage.getItem('cookieBoxPopup'),
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user.userInfo;
      state.rights = user.userInfo.rights;
      state.subscription = user.userInfo.userSubscriptionPlan;
      localStorage.setItem('user', state.user);
      localStorage.setItem('rights', state.user.rights);
      localStorage.setItem('subscription', state.user.userSubscriptionPlan);
      state.isAuthenticated = true;
      localStorage.setItem('isAuthenticated', state.isAuthenticated);
      state.token = user.token;
      localStorage.setItem('token', state.token);
      state.email = user.userInfo.email;
      localStorage.setItem('email', state.email);
    },
     SET_USER_RIGHTS_AND_SUBSCRIPTION(state, newRights) {

      state.rights = newRights.rights;
      state.subscription = newRights.userSubscriptionPlan;

      localStorage.setItem('rights', state.rights);
      localStorage.setItem('subscription', state.userSubscriptionPlan);
  
    },
     SET_COOKIEBOX_POPUP(state) {
       state.cookieBoxPopup = false;
      localStorage.setItem('cookieBoxPopup', false);
  
    },
    CLEAR_USER(state) {
      localStorage.removeItem('rights');
       localStorage.removeItem('subscription');
      state.user = null;
      localStorage.removeItem('user');
      state.email = null;
      localStorage.removeItem('email');
      state.token = null;
      localStorage.removeItem('token');
      state.isAuthenticated = false;
      localStorage.removeItem('isAuthenticated');
    }
  },
  actions: {
    login({ commit }, user) {
      // 这里可以执行异步操作，比如调用登录API
      commit('SET_USER', user);
    },
     updateRights({ commit }, newRights) {
      // 这里可以执行异步操作，比如调用登录API
      commit('SET_USER_RIGHTS_AND_SUBSCRIPTION', newRights);
    },
    logout({ commit }) {
      // 这里可以执行登出逻辑
      commit('CLEAR_USER');
    },
    cookieBoxPopup({ commit }) {
      // 这里可以执行登出逻辑
      commit('SET_COOKIEBOX_POPUP');
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    token(state) {
      return state.token;
    },
    avatar(state) {
      return state.user.avatar;
    },
    email(state) {
          return state.email;  
    },
    credits(state) {
      if (state.rights === null || state.rights === undefined) {
          console.log("state.rights：", state.rights);
          return 0;
        }
        if (state.rights.leftGenSongs === null || state.rights.leftGenSongs === undefined) {
            console.log("state.rights no leftGenSongs：", state.rights.leftGenSongs," rights:", state.rights);
          return 0;
      }
      console.log("state.rights leftGenSongs：", state.rights.leftGenSongs);
        return state.rights.leftGenSongs;  
    },
     maxCredits(state) {
        if (state.rights === null || state.rights === undefined) {
          return 3;
        }
        if (state.rights.maxGenSongs === null || state.rights.maxGenSongs === undefined) {
          return 3;
        }
          return state.rights.maxGenSongs;  
    },
    planName(state) {
       if (
         state.subscription == undefined ||
         state.subscription.subscriptionPlan === null ||
         state.subscription.subscriptionPlan === undefined
       ) {
         return "Free Plan";
       }
        return state.subscription.subscriptionPlan;
    },
    freePlan(state) {
        if (state.subscription === null || state.subscription === undefined) {
          return true;
        }
        if (state.subscription.type === null || state.subscription.type === undefined || state.subscription.type === 0) {
          return true;
        }
        return false;  
    },
    hasCommercialCopyright(state) {
        if (state.subscription === null || state.subscription === undefined) {
          return false;
        }
        if (state.subscription.type === null || state.subscription.type === undefined || state.subscription.type === 0) {
          return false;
        }
        return state.subscription.subscriptionPlan !== 'Basic Monthly Payment';  
    },
    chagrePlan(state) {
      if (state.subscription === null || state.subscription === undefined) {
          return false;
        }
        if (state.subscription.type === null || state.subscription.type === undefined || state.subscription.type === 0) {
          return false;
        }
      return true;
    },
    planInSubscribing(state) {
      if (state.subscription === null || state.subscription === undefined) {
          return false;
      }
      if (state.subscription.type === null || state.subscription.type === undefined || state.subscription.type === 0) {
          return false;
      }
      if (state.subscription.status === 0) {
        return false;
      }
      return true;
    },
    subscription(state) {
      return state.subscription;
    },
    name(state) {
      if (state.email) {
        return state.email.split('@')[0];
      }
      return '';
    } ,
    cookieBoxPopup(state) {
     
        if (state.cookieBoxPopup === null || state.cookieBoxPopup === undefined) {
          return true;
        }
        return state.cookieBoxPopup;  
    }
  }
});



// 创建 axios 实例
// 添加请求拦截器
axios.interceptors.request.use(config => {

  const token = store.state.token; // 动态获取 token
  if (token) {
    config.headers['token'] = token;
  }
  config.headers['language_code'] = 'en';
  
  const lan = localStorage.getItem("lan");
  if (lan !== null && lan !== undefined && lan !== '') {
      config.headers['language_code'] = lan;

  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}, error => {
  return Promise.reject(error);
});

export default store;
