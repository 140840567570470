<template>
    <div class="container">
        <div class="gen_title" v-if="extend_mode !== true">
            <span class="title">{{ t('SONG AI MUSIC GENERATOR') }}</span>
            <span class="info">{{ t('Create your own unparalleled music for free, male or female voices, free MP3 songs and MP4 videos.') }}</span>
            <span class="info">{{ t('Download and enjoy innovative music instantly!') }}</span>
        </div>
        <div class="gen_params" v-if="$store.getters.isAuthenticated !== null">
            <div class="mode_params">
                <div class="mode_switch_group">
                    <div class="mode_switch">
                        <el-switch v-model="customMode" size="large"></el-switch>
                        <span>{{ t('Custom Mode') }}</span>
                    </div>
                    <div class="mode_switch">
                        <el-switch v-model="instrumental" size="large"></el-switch>
                        <span>{{ t('Instrumental') }}</span>
                    </div>
                </div>

                <el-dropdown trigger="click">
                    <el-button class="mode_params-version" style="background: transparent">
                        <span>
                            {{ t('AI Model Version') }} :
                            <span class="version-span">{{ gen_params.mv }} </span>
                        </span>
                        <svg class="version-icon" width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.65167 10.6426H8.81461C8.75769 10.6426 8.70412 10.6705 8.67064 10.7162L5.49988 15.0868L2.32912 10.7162C2.29564 10.6705 2.24207 10.6426 2.18515 10.6426H1.34809C1.27555 10.6426 1.23314 10.7252 1.27555 10.7843L5.21082 16.2095C5.35367 16.406 5.64608 16.406 5.78783 16.2095L9.72309 10.7843C9.76662 10.7252 9.72421 10.6426 9.65167 10.6426Z" fill="white" />
                        </svg>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu class="music-gen-version-menu-container">
                            <el-dropdown-item class="item" @click="handleSelectModeVersion('V6.0')">
                                <span class="title">V6.0</span>
                                <span class="desc">{{ t('Newest model, bettersong structure, max 4 minutes') }}</span>
                            </el-dropdown-item>
                            <el-dropdown-item class="item" @click="handleSelectModeVersion('V4.0')">
                                <span class="title">V4.0</span>
                                <span class="desc">{{ t('Broad, versatile, max 2 minutes') }}</span>
                            </el-dropdown-item>
                            <el-dropdown-item class="item" @click="handleSelectModeVersion('V3.0')">
                                <span class="title">V3.0</span>
                                <span class="desc">{{ t('Vintage SongAI model, max 1.3 minutes') }}</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div class="gen_params_group">
                <div v-if="customMode" style="width: 100%">
                    <div class="gen_params_item">
                        <div class="gen_params_title">
                            <span class="gen_params_text">{{ t('Title') }}</span>
                            <el-popover popper-class="music-gen-params-title-question" placement="right" :width="600" popper-style="background: #c8ff15; color:black; font-size:20px" trigger="hover" :content="t('Give your song a title for sharingdiscovery and organization.')">
                                <template #reference>
                                    <img :src="questionCircleUrl" class="question_circle" />
                                </template>
                            </el-popover>
                        </div>
                        <div class="title_container">
                            <textarea class="gen_params_value_text gen_params_value_text_title" rows="1" @input="handleTitleInput" v-model="gen_params.title" :placeholder="defualtTitle"></textarea>
                            <span class="title_count">{{ titleCharacterCount() }}/{{ maxTitleCharacterCount }}</span>
                        </div>
                    </div>

                    <div class="gen_params_item">
                        <div class="gen_params_style">
                            <div class="gen_params_title">
                                <span class="gen_params_text">{{ t('Style Of Music') }}</span>
                                <el-popover popper-class="music-gen-params-title-question" placement="right" popper-style="background: #c8ff15; color:black; font-size:20px" :width="700" trigger="hover" :content="t('Describe the style of music you want (e.g. acoustic pop). SongAI models do not recognize artists names,but do understand genres and vibes.')">
                                    <template #reference>
                                        <img :src="questionCircleUrl" class="question_circle" />
                                    </template>
                                </el-popover>
                            </div>
                            <el-button class="random_style_button" @click="randomSelectStyle">
                                <span class="random_style_text">{{ t('Use random style') }}</span>
                                <img :src="reloadIconUrl" class="random_style_icon" />
                            </el-button>
                        </div>

                        <textarea class="gen_params_value_text" v-model="gen_params.tags" :placeholder="defaultStyle"></textarea>
                    </div>

                    <div class="gen_params_item" v-if="!instrumental">
                        <div class="gen_params_style">
                            <div class="gen_params_title">
                                <span class="gen_params_text">{{ t('Lyrics') }}</span>
                                <el-popover popper-class="music-gen-params-title-question" placement="right" popper-style="background: #c8ff15; color:black; font-size:20px" :width="600" trigger="hover" :content="t('Write your own lyrics or get some help from Al. Use two verses (8 lines) for best results.')">
                                    <template #reference>
                                        <img :src="questionCircleUrl" class="question_circle" />
                                    </template>
                                </el-popover>
                            </div>
                            <el-button :loading="randomLyricsLoading" class="random_style_button" @click="randomLyrics">
                                <span class="random_style_text">{{ t('Generate lyrics') }}</span>
                                <img :src="reloadIconUrl" class="random_style_icon" />
                            </el-button>
                        </div>
                        <div class="title_container">
                            <textarea class="gen_params_value_text" @input="handlePromptInput" v-model="gen_params.prompt" :placeholder="t('Enter your own lyrics, or describe a song and click generate lyrics, or direct click generate lyrics and will generate lyrics for you by the title')" :maxlength="maxLyricsLength"> </textarea>
                            <span class="title_count">{{ lyricsCharacterCount() }}/{{ maxLyricsLength }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!customMode" class="gen_params_item">
                    <div class="gen_params_title">
                        <span class="gen_params_text">{{ t('Song Description') }}</span>
                        <el-popover popper-class="music-gen-params-title-question" placement="right" popper-style="background: #c8ff15; color:black; font-size:20px" :width="700" trigger="hover" :content="t('Describe the style of music and topic you want (e.g. acoustic pop about the holidays).Use genres and vibes instead of specific artists and songs.')">
                            <template #reference>
                                <img :src="questionCircleUrl" class="question_circle" />
                            </template>
                        </el-popover>
                    </div>
                    <div class="title_container">
                        <textarea class="gen_params_value_text" v-model="gen_params.description" :placeholder="defaultDescription" :maxlength="maxDescriptionLength"></textarea>

                        <span class="title_count">{{ descriptionCharacterCount() }}/{{ maxDescriptionLength }}</span>
                    </div>
                </div>
                <el-radio-group class="gen_params_radio_group" v-if="!instrumental" v-model="gen_params.voice">
                    <el-radio size="large" label="1">
                        <span>{{ t('Female Voice') }}</span>
                    </el-radio>
                    <el-radio size="large" label="2">
                        <span>{{ t('Male Voice') }}</span>
                    </el-radio>
                    <el-radio size="large" label="0">
                        <span>{{ t('Random') }}</span>
                    </el-radio>
                </el-radio-group>
                <button class="gen_button" @click="generateMusic">
                    <span class="gen_button_text" v-if="extend_mode !== true">{{ t('Get Started') }}</span>
                    <span v-else>{{ t('Extend') }}</span>
                    <img :src="musicNoteUrl" class="gen_button_icon" />

                    <img :src="loadingIconUrl" :fit="fit" class="gen_button_loading" v-if="buttonLoading" />
                </button>
            </div>
        </div>
        <div class="gen_params" v-else>
            <button class="gen_button" @click="generateMusic">
                <span class="gen_button_text" v-if="extend_mode !== true">{{ t('Get Started') }}</span>
                <span v-else>{{ t('Extend') }}</span>
                <img :src="musicNoteUrl" class="gen_button_icon" />

                <img :src="loadingIconUrl" :fit="fit" class="gen_button_loading" v-if="buttonLoading" />
            </button>
        </div>
    </div>
</template>

<script>
import { apiSongGen, apiRandomLyricsGen, apiLyricsTaskQuery } from '@/api/service';
import { ElMessage } from 'element-plus';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ArrowDownSvg from '@/assets/icon-arrow-down.svg';

export default {
    name: 'music_gen',
    props: ['extend_mode', 'extend_clip', 'extend_from'],
    setup() {
        const { t } = useI18n();

        return { t };
    },
    data() {
        return {
            customMode: true,
            buttonLoading: false,
            instrumental: false,
            randomLyricsLoading: false,
            lyricTaskInfo: {
                taskId: null,
                intervalId: null
            },
            questionCircleUrl: require('@/assets/question-circle.svg'),
            musicNoteUrl: require('@/assets/music_note.svg'),
            reloadIconUrl: require('@/assets/icon-reload.svg'),
            loadingIconUrl: require('@/assets/button_loading.webp'),
            ArrowDownSvg: ArrowDownSvg,
            mvmap: new Map([
                ['V6.0', 'chirp-v3-5'],
                ['V4.0', 'chirp-v3-0'],
                ['V3.0', 'chirp-v2-xxl-alpha']
            ]),
            randomStyle: [
                'Pop,Electronic',
                'Rock,Alternative',
                'Hip Hop,R&B',
                'Jazz,Blues',
                'Country,Folk',
                'Dance,House',
                'Reggae,Dub',
                'Classical,Opera',
                'Metal,Hard Rock',
                'Indie,Alternative Rock',
                'EDM,Trance',
                'Soul,Funk',
                'Punk,Hardcore',
                'Latin,Salsa',
                'Ambient,Chillout',
                'Techno,Minimal',
                'Gospel,Contemporary Christian',
                'Synthwave,New Wave',
                'Progressive Rock,Psychedelic Rock',
                'Acoustic,Singer-Songwriter',
                'Trip Hop,Downtempo',
                'Experimental,Avant-Garde',
                'Garage Rock,Post-Punk',
                'Swing,Big Band',
                'Bluegrass,Traditional Country',
                'House,Tech House',
                'Grunge,Post-Grunge',
                'Classical Crossover,New Age',
                'Dubstep,Drum,Bass',
                'Electro Pop,Synthpop',
                'Indie Pop,Dream Pop',
                'Folk Rock,Americana',
                'Country Pop,Country Rock',
                'Smooth Jazz,Contemporary Jazz',
                'Psytrance,Goa Trance',
                'Lo-Fi Hip Hop,Chill Beats',
                'Alternative R&B,Neo-Soul',
                'Ska,Rocksteady',
                'Electro Swing,Jazz Manouche',
                'Experimental Electronic,IDM',
                'Symphonic Metal,Gothic Metal',
                'Melodic Techno,Progressive House',
                'Synthwave,Vaporwave',
                'Industrial,EBM (Electronic Body Music)',
                'World Music,Fusion',
                'Tropical House,Deep House',
                'Classic Rock,Arena Rock',
                'Rave,Gabber',
                'New Wave,Post-Punk Revival',
                'Ambient Pop,Ethereal Wave'
            ],
            maxTitleCharacterCount: 40,
            maxLyricsLength: 3000,
            maxDescriptionLength: 200,
            defaultStyle: 'indie-pop, soulful, dreamy, psychedelic',
            defaultDescription: computed(() => this.$i18n.t('an infectious grunge song about finding love on a rainy day')),
            defualtTitle: computed(() => this.$i18n.t('oh, my love')),
            defaultLyrics: computed(() => this.$i18n.t('defaultLyrics')),
            gen_params: {
                promptDefault: true,
                gptPrompt: false,
                titleDefault: true,
                prompt: this.$i18n.t('defaultLyrics'),
                customMode: null,
                instrumental: null,
                tags: 'indie-pop, soulful, dreamy, psychedelic',
                title: this.$i18n.t('oh, my love'),
                voice: '0',
                description: this.$i18n.t('an infectious grunge song about finding love on a rainy day'),
                mv: 'V6.0'
            }
        };
    },
    created() {
        console.log('extend_mode:', this.extend_mode, ' extend_clip:', this.extend_clip, 'extend_from ', this.extend_from);
    },
    methods: {
        handleTitleInput() {
            if (this.gen_params.title !== this.defualtTitle) {
                this.gen_params.titleDefault = false;
                if (this.gen_params.promptDefault) {
                    this.gen_params.prompt = '';
                    this.gen_params.promptDefault = false;
                } else if (this.gen_params.title === '' && this.gen_params.prompt === '') {
                    this.gen_params.prompt = this.defaultLyrics;
                    this.gen_params.promptDefault = true;
                }
            } else {
                this.gen_params.titleDefault = true;
                if (this.gen_params.prompt === '') {
                    this.gen_params.prompt = this.defaultLyrics;
                    this.gen_params.promptDefault = true;
                }
            }
        },
        handlePromptInput() {
            this.gen_params.promptDefault = false;
            this.gen_params.gptPrompt = false;
        },
        lyricGenEnabled() {
            var lyricChCnt = this.lyricsCharacterCount();

            var titleChCnt = this.titleCharacterCount();

            return lyricChCnt > 0 || titleChCnt > 0;
        },
        validLyrics() {
            return this.lyricsCharacterCount() > 0;
        },
        lyricsCharacterCount() {
            if (this.gen_params.prompt === null || this.gen_params.prompt === undefined || this.gen_params.prompt === '') {
                return 0;
            }
            return this.gen_params.prompt.length;
        },
        titleCharacterCount() {
            if (this.gen_params.title === null || this.gen_params.title === undefined || this.gen_params.title === '') {
                return 0;
            }
            return this.gen_params.title.length;
        },
        descriptionCharacterCount() {
            if (this.gen_params.description === null || this.gen_params.description === undefined || this.gen_params.description === '') {
                return 0;
            }
            return this.gen_params.description.length;
        },
        handleSelectModeVersion(version) {
            console.log('handleSelectModeVersion ', version);
            this.gen_params.mv = version;
        },
        generateMusic() {
            console.log('click generateMusic:', this.gen_params);
            this.buttonLoading = true;
            if (!this.$store.getters.isAuthenticated) {
                return this.$router.push({ name: 'login_account' });
            }

            if (!this.customMode) {
                if (this.gen_params.description === null || this.gen_params.description === '') {
                    this.gen_params.description = this.defaultDescription;
                }
            } else {
                this.gen_params.description = null;

                if (this.gen_params.title === null || this.gen_params.title === '') {
                    this.gen_params.title = this.defualtTitle;
                }

                if (this.gen_params.tags === null) {
                    this.gen_params.tags = this.defaultStyle;
                }
            }

            if (this.instrumental) {
                //乐器模式,没有歌词

                this.gen_params.prompt = null;
                this.gen_params.instrumental = true;
            }

            var request = {
                seqId: new Date().getTime(),
                metadata: {
                    tags: this.gen_params.tags,
                    title: this.gen_params.title,
                    mv: this.mvmap.get(this.gen_params.mv),
                    prompt: this.gen_params.prompt,
                    generation_type: 'TEXT',
                    gpt_description_prompt: this.gen_params.description,
                    instrumental: this.gen_params.instrumental
                },
                voiceType: this.gen_params.voice
            };

            if (this.extend_mode && this.extend_clip !== null && this.extend_clip !== '' && this.extend_clip !== undefined) {
                request.metadata.task = 'extend';
                request.metadata.continue_clip_id = this.extend_clip;
                request.metadata.continue_at = this.extend_from;
            }
            this.buttonLoading = true;
            try {
                apiSongGen(request).then((res) => {
                    console.log('apiSongGen request:', request, ' res:', res);
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            this.buttonLoading = false;
                            this.$router.push({ path: '/my_music' });
                        } else {
                            ElMessage.error(res.data.message);
                            if (res.data.code === 514) {
                                this.$router.push({ path: '/price_plan' });
                            }
                        }
                    } else {
                        ElMessage.error('Netword Error, please retry');
                    }

                    this.buttonLoading = false;
                });
            } catch (error) {
                console.error('apiSongGen failed:', error);
            }
        },
        randomSelectStyle() {
            var index = Math.floor(Math.random() * this.randomStyle.length);
            this.gen_params.tags = this.randomStyle[index];
        },
        fetchLyricTaskStatus() {
            if (this.lyricTaskInfo.taskId === null) {
                clearInterval(this.lyricTaskInfo.intervalId);
                this.lyricTaskInfo.intervalId = null;
                return;
            }

            apiLyricsTaskQuery(this.lyricTaskInfo.taskId).then((res) => {
                console.log('apiLyricsTaskQuery taskId:', this.lyricTaskInfo.taskId, ' res:', res);
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.gen_params.prompt = res.data.data.generateInfo.metadata.prompt;
                        this.gen_params.gptPrompt = true;

                        clearInterval(this.lyricTaskInfo.intervalId);
                        this.lyricTaskInfo.intervalId = null;
                        this.lyricTaskInfo.taskId = null;
                        this.randomLyricsLoading = false;
                        return;
                    }
                }
            });
        },
        randomLyrics() {
            var lyricsPrompt = this.gen_params.prompt;
            if (this.gen_params.gptPrompt || this.gen_params.promptDefault) {
                lyricsPrompt = null;
            }
            if (lyricsPrompt === null || lyricsPrompt === '' || lyricsPrompt === undefined) {
                lyricsPrompt = this.gen_params.title;
                if (this.gen_params.title === '' || this.gen_params.title === null || this.gen_params.title === undefined) {
                    lyricsPrompt = this.defualtTitle;
                }
            }

            console.log('randomLyrics ', lyricsPrompt);
            this.randomLyricsLoading = true;
            apiRandomLyricsGen(lyricsPrompt).then((res) => {
                console.log('apiRandomLyricsGen lyricsPrompt:', lyricsPrompt, ' res:', res);
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        this.lyricTaskInfo.taskId = res.data.data.generateInfo.id;
                        this.lyricTaskInfo.intervalId = setInterval(this.fetchLyricTaskStatus, 1000);
                    } else {
                        ElMessage.error(res.data.message);
                        this.randomLyricsLoading = false;
                    }
                } else {
                    ElMessage.error('Netword error, please try again');
                    this.randomLyricsLoading = false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.gen_params_radio_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
    order: 2;

    :deep(.el-radio__label) {
        color: white !important;
    }

    :deep(.is-checked) {
        .el-radio__inner {
            //background: var(--bule-light) !important;
            //border-color: var(--bule-light) !important;
            background: var(--yellow) !important;
            border-color: var(--yellow) !important;
        }

        .el-radio__label {
            //color: var(--bule-light) !important;
            color: var(--yellow) !important;
        }
    }
}

.random_style_icon {
    width: 16px;
    height: 16px;
}

.gen_button {
    /* State=Default, Value=Primary, Size=Large */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 4px;

    width: auto;
    height: 48px;

    background: var(--yellow);
    border-radius: var(--border-radius);

    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    transition: box-shadow 0.2s ease-out;
    border-color: transparent;
    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 10px 2px var(--yellow-op);
    }
}

.gen_button_text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--black);
}

.gen_button_icon {
    /* music_note */

    width: 24px;
    height: 24px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gen_button_loading {
    /* music_note */

    width: 24px;
    height: 24px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

.title_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.title_count {
    position: relative;
    margin-right: 20px;
    margin-top: -20px;
    color: gray;
    order: 2;
}

.gen_params_value_text {
    box-sizing: border-box;
    padding: 16px 16px;
    font-weight: 500;
    font-size: 20px;

    height: 200px;
    resize: vertical;

    //color: var(--black);
    color: var(--black);
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: var(--border-radius);
    align-self: stretch;
}

.gen_params_value_text_title {
    resize: none;
    height: auto;
}

.question_circle {
    width: 28px;
    height: 28px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.gen_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 0;
    margin-bottom: 48px;

    .title {
        font-weight: 700;
        font-size: 4rem;
        text-align: center;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .info {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        text-transform: capitalize;
        color: var(--gray);
    }
}

.gen_params {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    min-width: 700px;
    order: 1;

    .mode_params {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 32px;

        .mode_switch_group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 24px;

            .mode_switch {
                display: flex;
                align-items: center;

                span {
                    margin-left: 4px;
                    font-size: 20px !important;
                }

                :deep(.is-checked .el-switch__core) {
                    //background-color: var(--bule-light) !important;
                    //border-color: var(--bule-light) !important;

                    background-color: var(--yellow) !important;
                    border-color: var(--yellow) !important;
                }

                :deep(.el-switch__core) {
                    background-color: var(--gray) !important;
                    border-color: var(--gray) !important;
                    height: 16px;
                    min-width: 42px;
                }

                :deep(.is-checked .el-switch__action) {
                    //border: 1px solid var(--bule-light) !important;
                    border: 1px solid var(--yellow) !important;
                }
            }
        }
    }

    .gen_params_group {
        display: flex;
        flex-direction: column;
        align-items: center;
        //gap: 32px;
        width: 100%;

        .gen_params_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            width: 100%;
            margin-bottom: 32px;

            .gen_params_style {
                display: flex;
                justify-content: space-between;
                width: 100%;
                overflow: hidden;
            }

            .gen_params_title {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                margin-bottom: 0;
                height: 32px;

                .gen_params_text {
                    font-weight: 700;
                    font-size: 28px;
                    margin-right: 4px;
                }
            }

            :deep(.random_style_button) {
                height: 32px !important;
                background-color: transparent !important;
                border-radius: 4px !important;

                .random_style_text {
                    margin-right: 8px;
                    color: white;
                }

                .random_style_icon {
                    transform: translateY(-100px);
                    filter: drop-shadow(0 100px 0 var(--yellow));
                }

                &:hover {
                    border-color: var(--yellow) !important;

                    .random_style_icon {
                        transform: translateY(-100px);
                        filter: drop-shadow(0 100px 0 var(--yellow));
                    }

                    .random_style_text {
                        color: var(--yellow) !important;
                    }
                }
            }
        }
    }
}

.mode_ctl {
    /* Frame 40196 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: auto;
    height: auto;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mode_version {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: auto;
    height: 38px;

    /* Neutral/1 */
    background: #ffffff;
    /* Neutral/5 */
    border: 1px solid #d9d9d9;
    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.mode_version_button {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    position: relative;
    width: auto;
    height: 38px;

    /* Neutral/1 */
    background: #ffffff;
    /* Neutral/5 */
    border: 1px solid #d9d9d9;
    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
}

.mode_version_value {
    /* title */

    width: 200px;
    height: auto;

    /* Body/regular */

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    /* Character/Primary .85 */
    color: rgba(0, 0, 0, 0.85);

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}

.question_circle_Popover {
    color: black;
    background: #c8ff15;
}

.el-radio.is-checked .el-radio__input.is-checked .el-radio__inner {
    width: 20px; /* 内圆直径 */
    height: 20px; /* 内圆直径 */
    background-color: #c8ff15; /* 自定义选中颜色 */
    border-color: #c8ff15; /* 自定义边框颜色 */
}

.el-radio__input .el-radio__inner {
    width: 22px !important;
    height: 22px !important;
}

.mode_params-version {
    height: 40px;
    color: white;
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px !important;

    .version-span {
        font-weight: 600;
        color: var(--yellow);
    }

    .version-icon {
        margin-left: 8px;

        path {
            stroke: var(--yellow) 1px !important;
        }
    }

    &:hover {
        color: var(--yellow) !important;
        border-color: var(--yellow) !important;

        path {
            fill: var(--yellow) !important;
        }
    }

    &:active {
        color: var(--yellow) !important;
        border-color: var(--yellow) !important;

        path {
            fill: var(--yellow) !important;
        }
    }
}

/* 移动端 */
@media (max-width: 1000px) {
    .gen_title {
        margin-bottom: 32px;

        .title {
            font-size: 2.5rem;
        }

        .info {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }
    }

    .gen_params {
        width: 100%;
        min-width: auto !important;

        .gen_params_item {
            gap: 0;
        }

        .mode_params {
            flex-direction: column;

            .mode_switch_group {
                width: 100%;
                justify-content: space-between;
                margin-bottom: 8px;

                .mode_switch span {
                    font-size: 16px !important;
                }
            }

            :deep(.el-dropdown) {
                width: 100%;

                .el-button {
                    width: 100%;
                }
            }
        }

        .gen_params_group {
            .gen_params_title {
                .gen_params_text {
                    font-weight: 600 !important;
                    font-size: 16px !important;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .question_circle {
                width: 16px;
                height: 16px;
            }

            .gen_params_radio_group {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 0;

                :deep(.el-radio) {
                    .el-radio__label {
                        span {
                            width: 30vw;
                            white-space: normal;
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.music-gen-version-menu-container {
    .item {
        height: 48px !important;

        &:hover {
            background-color: var(--bule-deep-bg) !important;
        }

        &:active {
            background-color: var(--bule-deep-bg) !important;
        }
    }

    .title {
        color: var(--bule-deep);
        font-size: 24px;
        margin-right: 8px;
        font-weight: 600;
    }

    .desc {
        color: var(--gray);
        font-size: 18px;
    }
}

.music-gen-params-title-question {
    background-color: rgb(232 255 156) !important;
    //background-color: var(--bule-deep) !important;
    border-color: white !important;
    //color: white !important;
    color: var(--black) !important;
    padding: 0 8px !important;
    font-size: 16px !important;
}

/* 移动端 */
@media (max-width: 1000px) {
    .music-gen-version-menu-container {
        width: 84vw !important;

        .item {
            height: 32px !important;

            .title {
                font-size: 18px;
                margin-right: 4px;
                font-weight: 600;
            }

            .desc {
                font-size: 12px;
                white-space: normal;
                line-height: 16px;
            }
        }
    }
}
</style>
