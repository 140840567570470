<template>
    <el-container class="top_container">
        <div class="logo">
            <img :src="logoUrl" @click="handleLogoClick"/>
        </div>
        <el-menu v-model="activeIndex" :default-active="activeIndex" class="nav_menu" active-text-color="#C8FF15" text-color="#FFFFFF"
                 mode="horizontal"
                 :router="true" @select="handleSelect">
            <el-menu-item index="AI Music" class="menu_title">{{ t('AI Music') }}</el-menu-item>
            <el-menu-item index="Pricing" class="menu_title">{{ t('Pricing') }}</el-menu-item>
            <el-menu-item index="My Music" class="menu_title">{{ t('My Music') }}</el-menu-item>
        </el-menu>
        <div class="login_area">
            <button class="login_button" v-if="!$store.getters.isAuthenticated" @click="hanleUserLogin">
                <span class="login_text">{{ t('Login') }}</span>
            </button>
            <el-dropdown @command="handleLanguageSwitch" popper-class="header-nav-dropdown-language-select">
                <el-button class="language">
                    <img class="language-icon" :src="LanguageSvg" alt="Language"/>
                    <span class="language-text">{{ localeLanguageForShow.toUpperCase() }}</span>
                    <img class="language-icon-arrow" :src="ArrowDownSvg" alt=""/>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-for="item in languages"
                                          :class="{'header-nav-dropdown-language-select-focus': localeLanguageForShow ===item.value}"
                                          :key="item.value" :command="item.value">
                            {{ item.label }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-dropdown v-if="$store.getters.isAuthenticated">
                <el-button type="primary" class="logined_button">
                    <el-avatar :size="50" :src="userAvartar()" class="user_avatar"/>
                    <div class="expand_logined">
                        <p class="loggined_text">{{ userName() }}</p>
                        <el-icon class="el-icon--right">
                            <arrow-down/>
                        </el-icon>
                    </div>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="handleLogout()">Logout</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-button class="credits" @click="viewMyCredits()" v-if="$store.getters.isAuthenticated">
                <span class="credits_text">{{ t('Credits') }}:</span>
                <span class="credits_text">{{ $store.getters.credits }}</span>
                <img :src="musicNoteUrl"/>
            </el-button>
        </div>
        <div class="mobile-menu">
            <el-button link @click="mobileDrawerVisible = true">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="yellow"
                     style="width: 24px; height: 24px; stroke: white; fill: none;">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
                </svg>
            </el-button>
            <el-drawer class="mobile-menu-drawer" v-model="mobileDrawerVisible" direction="rtl"
                       append-to-body close-on-click-modal>
                <template #header>
                    <div class="logo-in-drawer">
                        <img :src="logoUrl" :fit="fit"/>
                    </div>
                </template>
                <template #default>
                    <div class="mobile-menu-drawer-link">
                        <el-button link @click="handleSelect('AI Music')">{{ t('AI Music') }}</el-button>
                        <el-button link @click="handleSelect('Pricing')">{{ t('Pricing') }}</el-button>
                        <el-button link @click="handleSelect('My Music')">{{ t('My Music') }}</el-button>
                    </div>
                    <el-divider></el-divider>
                    <el-dropdown @command="handleLanguageSwitch" popper-class="header-nav-dropdown-language-select" trigger="click">
                        <el-button class="language">
                            <img class="language-icon" :src="LanguageSvg" alt="Language"/>
                            <span class="language-text">{{ localeLanguageForShow.toUpperCase() }}</span>
                            <img class="language-icon-arrow" :src="ArrowDownSvg" alt=""/>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="item in languages"
                                                  :class="{'header-nav-dropdown-language-select-focus': localeLanguageForShow ===item.value}"
                                                  :key="item.value" :command="item.value">
                                    {{ item.label }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <div style="height: 24px"/>
                    <!--未登陆-->
                    <template v-if="!$store.getters.isAuthenticated">
                        <button class="mobile-menu-drawer-login"
                                @click="()=>{hanleUserLogin();mobileDrawerVisible=false}">
                            <span class="login_text">{{ t('Login') }}</span>
                        </button>
                    </template>
                    <!--已登陆-->
                    <template v-else>

                        <el-dropdown v-if="$store.getters.isAuthenticated">
                            <el-button type="primary" class="logined_button">
                                <el-avatar :size="50" :src="userAvartar()" class="user_avatar"/>
                                <div class="expand_logined">
                                    <p class="loggined_text">{{ userName() }}</p>
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </div>
                            </el-button>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="handleLogout()">Logout</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <div style="height: 24px"/>
                        <el-button class="credits" @click="viewMyCredits()" v-if="$store.getters.isAuthenticated">
                            <span class="credits_text">{{ t('Credits') }}:</span>
                            <span class="credits_text">{{ $store.getters.credits }}</span>
                            <img :src="musicNoteUrl"/>
                        </el-button>
                    </template>
                </template>
            </el-drawer>
        </div>
    </el-container>
</template>

<script>
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {ArrowDown} from '@element-plus/icons-vue';
import LanguageSvg from '@/assets/language.svg';
import ArrowDownSvg from '@/assets/icon-arrow-down.svg';
import {apiAccountRights} from '@/api/service';
import {useI18n} from 'vue-i18n';
import {ref, watch, onMounted} from 'vue';


export default {
    name: 'header_nav',
    components: {ArrowDown},
    setup() {
        const store = useStore();
        const router = useRouter();

        const activeIndex = ref('');

        const defaultAvartar = ref(require('@/assets/default_avatar.svg'));
        const logoUrl = ref(require('@/assets/logo.svg'));
        const arrowDownUrl = ref(require('@/assets/icon-arrow-down.svg'));
        const musicNoteUrl = ref(require('@/assets/music_note.svg'));
        const mobileDrawerVisible = ref(false);
        const languages = [
                {label: 'English', value: 'en'},
                {label: 'Deutsch', value: 'de'},
                {label: 'Español', value: 'es'},
                {label: 'Français', value: 'fr'},
                {label: 'Bahasa Indonesia', value: 'id'},
                {label: 'Italiano', value: 'it'},
                {label: '日本語', value: 'ja'},
                {label: 'Português', value: 'pt'}
            ]
        ;
        const {t, locale} = useI18n();
        const localeLanguageForShow = ref(locale);
        const hanleUserLogin = () => {
            console.log('hanleUserLogin');
            if (!store.getters.isAuthenticated) {
                router.push({name: 'login_account'});
            }
        };

        const isLoggedIn = () => {
            return store.getters.isAuthenticated;
        };

        const userAvartar = () => {
            const avatar = store.getters.avatar;
            if (avatar && avatar !== '') {
                return avatar;
            }

            return defaultAvartar.value;
        };

        const userName = () => {
            let name = store.getters.name;
            if (name && name.length > 8) name = name.slice(0, 4) + "..."
            return name;
        };

        const handleLogout = () => {
            if (store.getters.isAuthenticated) {
                console.log('hanleLogout');
                store.dispatch('logout');
                router.push({ name: 'login_account' });
            }
        };
        const routeDashboard = () => {
            console.log('routeDashboard');
        };

        const viewMyCredits = () => {
            console.log('viewMyCredits');
            mobileDrawerVisible.value = false;
            router.push({name: 'my_subscription'});
        };

        const handleSelect = (key, keyPath) => {
            if (key === 'AI Music') {
                activeIndex.value = 'AI Music';
                router.push({name: 'body_main'});
            } else if (key === 'Pricing') {
                activeIndex.value = 'Pricing';
                router.push({name: 'price_plan'});
            } else if (key === 'My Music') {
                activeIndex.value = 'My Music';
                router.push({name: 'my_music'});
            }

            mobileDrawerVisible.value = false; // 用于处理 移动端跳转菜单后，关闭抽屉。
            console.log('key:', key, ' keyPath:', keyPath);
        };

        const handleLogoClick = () => {
            console.log('handleLogoClick');
            activeIndex.value = 'AI Music';
            router.push({ name: 'body_main' });
        };


        const handleRefreshRights = () => {
            if (store.getters.isAuthenticated) {
                apiAccountRights().then((res) => {
                    console.log('handleRefreshRights:', res);
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            store.dispatch('updateRights', res.data.data);
                        }
                    }
                });
            }
        };

        function handleLanguageSwitch(language) {
            locale.value = language;
            localStorage.setItem('lan', language);
        }

        function initLanguageByNavigator() {
            if (!localStorage.getItem('lan')) {
                const navigatorLanguages = navigator.languages;
                for (let _language of languages) {
                    if (navigatorLanguages.includes(_language.value)) {
                        handleLanguageSwitch(_language.value)
                        return;
                    }
                }
            }
        }


        watch(
            () => router.currentRoute.value.path,
            (newPath) => {
                if (newPath.includes('body_main')) {
                    activeIndex.value = 'AI Music';
                } else if (newPath.includes('price_plan')) {
                    activeIndex.value = 'Pricing';
                } else if (['/my_music', '/login_account'].includes(newPath)) {
                    activeIndex.value = 'My Music';
                } else {
                    activeIndex.value = ''
                }
            },
            {immediate: true}
        );

        onMounted(() => {
            handleRefreshRights();
            initLanguageByNavigator();
            router.isReady().then(() => {
                const currentPath = router.currentRoute.value.path;

                if (currentPath.includes('body_main')) {
                    activeIndex.value = 'AI Music';
                } else if (currentPath.includes('price_plan')) {
                    activeIndex.value = 'Pricing';
                } else if (['my_music', 'login_account'].includes(currentPath)) {
                    activeIndex.value = 'My Music';
                }

            });
        });

        return {
            hanleUserLogin,
            handleLogout,
            routeDashboard,
            handleSelect,
            arrowDownUrl,
            activeIndex,
            isLoggedIn,
            userAvartar,
            userName,
            logoUrl,
            musicNoteUrl,
            viewMyCredits,
            handleRefreshRights,
            handleLogoClick,
            handleLanguageSwitch,
            LanguageSvg,
            ArrowDownSvg,
            languages,
            mobileDrawerVisible,
            t,
            localeLanguageForShow,
        };
    },
};
</script>

<style lang="scss" scoped>
.top_container {
    height: 100% !important;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //gap: 10vw;
    background: transparent;

    top: 20px;
}
.logo {
    width: auto;
    flex: none;
    order: 0;
    flex-grow: 0;
    &-in-drawer {
        margin-right: 0;
    }
}
.nav_menu {
    //min-width: 700px;
    width: 50%;
    background: transparent;
    justify-content: space-between;
    align-items: center;
    flex: none;
    order: 1;
    gap: 0;
    flex-grow: 0;

    border-bottom: none !important;

    :deep(.el-menu-item) {
        border-bottom: none !important;
    }
}
.menu_title {
    background-color: transparent !important;
    /* Pricing */

    width: auto;
    height: 34px;


    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    /* identical to box height, or 34px */

    color: #ffffff;
    background: transparent;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.login_button {
    padding: 10px 44px;
    height: 48px;
    background: #c8ff15;
    border-radius: var(--border-radius);
    order: 2;

    transition: box-shadow 0.2s ease-out;
    border-color: transparent;

    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 10px 2px var(--yellow-op);
    }
}
.login_text {
    /* Login */

    width: 54px;
    height: 24px;


    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #000000;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.logined_button {
    /* Auto Layout Horizontal */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 6px;
    gap: 8px;

    margin: 0 auto;
    width: 120px;
    height: 49px;

    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: var(--border-radius);
    background: transparent;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.user_avatar {
    /* Ellipse 52 */
    margin-left: 10px;
    width: 40px;
    height: 40px;
    background: transparent;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.el-avatar__image {
    object-fit: cover; /* 确保图像完全填充容器 */
    width: 100%; /* 确保图像宽度为容器宽度 */
    height: 100%; /* 确保图像高度为容器高度 */
}
.expand_logined {
    /* Dropdown-Trigger*/

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0px;
    gap: 4px;
    background: transparent;

    width: auto;
    height: 32px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏超出容器的文本 */
    text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}
.loggined_text {
    /* title */

    width: 36px;
    height: 22px;
    margin-left: 10px;

    /* Body/regular */

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.credits {
    /* Auto Layout Horizontal */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 12px 6px;

    width: auto;
    height: auto;

    background: #c8ff15;
    border-radius: var(--border-radius);

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

.credits_text {
    /* title */

    width: auto;
    height: auto;


    font-style: normal;
    font-weight: 700;
    font-size: 20px;

    color: #000000;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.login_area {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex: none;
    order: 2;
}

.logined_button:hover {
    background: transparent;
}

.nav_menu:hover {
    background: transparent;
}
.el-menu-item.is-active,
.el-menu-item.is-active:focus,
.el-menu-item.is-active:active {
    background-color: transparent !important;
}

.menu_title:hover {
    background: transparent !important;
}

.li {
    background-color: transparent !important; /* 替换为你想要的颜色 */
}

.language {
    background-color: transparent !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 8px;
    //width: 74px;
    height: 44px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: var(--border-radius);
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white;

    &-icon {
        &-arrow {
            margin-left: 8px;
        }
    }

    &-text {
        color: white !important;
        position: relative;
        right: -6px;
    }
}

.mobile-menu {
    display: none;
}


/* 移动端 */
@media (max-width: 1400px) {
}

/* 移动端 */
@media (max-width: 1000px) {
    .top_container {
        width: 100%
    }
    .nav_menu {
        display: none;
    }
    .login_area {
        display: none;
    }
    .logo {
        margin: 0;
    }
    .mobile-menu {
        display: block;
    }

}

</style>

<style lang="scss">
.mobile-menu-drawer {
    width: 100% !important;
    background: var(--bule-deep) !important;

    &-link {
        display: flex;
        flex-direction: column;
        align-items: start;
        color: white !important;

        .el-button {
            color: white !important;
            margin-bottom: 28px;
            font-size: 22px;

            &:hover {
                text-decoration: underline
            }
        }

        .el-divider--horizontal {
            border-top: 1.5px hsl(215deg 28% 17%) var(--el-border-style);
        }

        .el-button + .el-button {
            margin-left: 0;

        }
    }

    &-login {
        padding: 10px 44px;
        height: 48px;
        background: #c8ff15;
        border-radius: var(--border-radius);
    }

    .credits_text {
        font-size: 16px;
        font-weight: normal;
    }
}
</style>


<style lang="scss">
.header-nav-dropdown-language-select {
    min-width: 200px !important;
    padding: 16px 0;
    margin-top: 6px !important;

    &-focus {
        background-color: var(--bule-deep-bg) !important;
        color: var(--bule-deep) !important;
    }

    .el-dropdown-menu__item {
        color: #000000;
        line-height: 32px;
        padding: 8px 24px !important;
        font-size: 16px;
        margin-bottom: 0;

        &:hover {
            font-weight: 600;
            background-color: var(--bule-deep-bg) !important;
            color: var(--bule-deep) !important;
        }

        &:focus {
            background-color: var(--bule-deep-bg) !important;
            color: var(--bule-deep) !important;
        }
    }


}
</style>

