import { createApp } from 'vue'

import App from './App.vue'
import store from './store';
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import StripeCheckout from "@vue-stripe/vue-stripe";
import { createI18n } from "vue-i18n";

import en from "./locales/en.json";
import zh from "./locales/zh-cn.json";
import de from "./locales/de.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import id from "./locales/id.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import pt from "./locales/pt.json";

const i18n = createI18n({
  locale: localStorage.getItem("lan") || "en", // 默认en
  fallbackLocale: "en",
  messages: {
    en,
    zh,
    de,
    es,
    fr,
    id,
    it,
    ja,
    pt,
  },
});



const app = createApp(App);
app.use(StripeCheckout, {
  pk: 'pk_live_51Psyum2McerLhhaNDafSvzXY59gwr9jhPXZ6tg7I3MrYcmez4XWyjRjLx1SZ4dvjNhu5WJshbRvo0cYvzJcPN5a9002Qz4MK4k',
});

app.use(store);

app.use(ElementPlus);
app.use(i18n);

app.use(router)
  .mount('#app')
