<template>
    <div class="plan">
        <span class="plan-title">{{ t('Get Plan For AI Music Factory Generator') }} </span>
        <nav class="plan-nav">
            <ul role="tablist" class="tabs">
                <li v-for="tab in tabs" :key="tab.name" :class="['tab', { active: tabSelected === tab.name }]" @click="handleTabChanged(tab.name)" role="tab">
                    <div class="tab-content">
                        <span class="visible-md">{{ $t(tab.label) }}</span>
                    </div>
                    <div v-if="tabSelected === tab.name" class="tab-overlay"></div>
                </li>
            </ul>
        </nav>

        <div class="plan_table" v-if="selectedPlan && selectedPlan.length">
            <!--<price_card v-for="plan in selectedPlan" :key="plan.id" :plan="plan"></price_card>-->
            <price_card :plan="selectedPlan[0]"></price_card>
            <price_card :plan="selectedPlan[1]"></price_card>
            <price_card :plan="selectedPlan[2]"></price_card>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'; // 引入defineAsyncComponent
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
const price_card = defineAsyncComponent(() => import('./price_card.vue'));

export default {
    name: 'price_strategy',
    components: { price_card },

    setup() {
        const { t } = useI18n();
        const basicPlans = [
            {
                bestDeal: false,
                id: 'Basic Monthly Payment',
                stripeProductId: 'prod_QoorqxdOPGKKrq',
                stripePriceId: 'price_1PxBE82McerLhhaN09ITn2Ba',
                title: 'Monthly Payment',
                price: '9.8',
                info: '25.3% choosed, Suitable for beginners to experience',
                keyFeatures: [],
                level: 1,
                features: ['Up to 600 songs maximum per month', 'Can generate songs up to 4 minutes long', '300 generate times per month, $0.04/Generate', '500 Download times per month', '10 running jobs at once', 'Music storage for up to 1 year']
            },
            {
                id: 'Basic Annually Payment',
                stripeProductId: 'prod_R4w1vUmxTduqCU',
                stripePriceId: 'price_1QCm9M2McerLhhaNdvC4fMxt',
                title: 'Annually Payment',
                price: '7.35',
                info: '41.9% choosed, Suitable for professional creators to create commercial music',
                level: 31,
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 600*12 songs maximum per year', '300*12 generate times per year, $0.02/Generate', '50 running jobs at once', 'Unlimited Download MP3 And MP4 Files'],
                bestDeal: true
            },
            {
                bestDeal: false,
                id: 'Basic Quarterly Payment',
                stripeProductId: 'prod_R4vzWTJjxJU2Bq',
                stripePriceId: 'price_1QCm7R2McerLhhaN4jm4WFmu',
                title: 'Quarterly Payment',
                price: '9.3',
                info: '32.8% choosed, Suitable for beginners to create commercial music',
                level: 21,
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 600*3 songs maximum per quarter', '300*3 generate times per quarter, $0.03/Generate', '20 running jobs at once', 'Unlimited Download MP3 And MP4 Files']
            }
        ];
        const premiumPlans = [
            {
                stripeProductId: 'prod_R0ckpICXKwbGk3',
                stripePriceId: 'price_1Q8bUn2McerLhhaNADROjuBx',
                bestDeal: false,
                id: 'Premium Monthly Payment',
                title: 'Monthly Payment',
                price: '29.8',
                info: '28.6% choosed, Suitable for professional creators to create commercial music',
                level: 2,
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 3000 songs maximum per month.', '1500 generate times per month, $0.02/Generate', '20 running jobs at once', 'Unlimited Download MP3 And MP4 Files']
            },
            {
                stripeProductId: 'prod_R0cm3Uec3li3vG',
                stripePriceId: 'price_1Q8bXK2McerLhhaNBLevTPkm',
                id: 'Premium Annually Payment',
                title: 'Annually Payment',
                price: '22.35',
                info: '46.5% choosed, Suitable for professional creators to create commercial music',
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 3000*12 songs maximum per year.', '1500*12 generate times per year, $0.015/Generate', '100 running jobs at once', 'Unlimited Download MP3 And MP4 Files'],
                bestDeal: true,
                level: 32
            },
            {
                stripeProductId: 'prod_R0coFeDiKmXA3z',
                stripePriceId: 'price_1Q8bYf2McerLhhaNANzecsuL',
                bestDeal: false,
                id: 'Premium Quarterly Payment',
                title: 'Quarterly Payment',
                price: '26.6',
                level: 22,
                info: '24.9% choosed, Suitable for professional creators to create commercial music',
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 3000*3 songs maximum per quarter.', '1500*3 generate times per quarter, $0.018/Generate', '50 running jobs at once', 'Unlimited Download MP3 And MP4 Files']
            }
        ];
        const advancedPlans = [
            {
                stripeProductId: 'prod_R0cp0p2qd8prwT',
                stripePriceId: 'price_1Q8bZw2McerLhhaNMzzu6Oaa',
                bestDeal: false,
                id: 'Advanced Monthly Payment',
                title: 'Monthly Payment',
                price: '49.8',
                level: 3,
                info: '15.3% choosed, Suitable for professional creators to create commercial music',
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 5600 songs maximum per month', '2800 generate times per month, $0.017/Generate', '50 running jobs at once', 'Unlimited Download MP3 And MP4 Files']
            },
            {
                stripeProductId: 'prod_R0crBqdjYtYfnG',
                stripePriceId: 'price_1Q8bc72McerLhhaNwV39NGxk',
                id: 'Advanced Annually Payment',
                title: 'Annually Payment',
                price: '37.5',
                level: 33,
                info: '59.8% choosed, Suitable for professional creators to create commercial music',
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 5600*12 songs maximum per year.', '2800*12 generate times per year, $0.013/Generate', '100 running jobs at once', 'Unlimited Download MP3 And MP4 Files'],
                bestDeal: true
            },
            {
                stripeProductId: 'prod_R0ctX1wAgHE3p4',
                stripePriceId: 'price_1Q8bdM2McerLhhaNIftBOeTq',
                bestDeal: false,
                id: 'Advanced Quarterly Payment',
                title: 'Quarterly Payment',
                price: '45',
                level: 23,
                info: '24.9% choosed, Suitable for professional creators to create commercial music',
                keyFeatures: ['Commercial use includes any use that generates revenue', 'Can generate songs up to 4 minutes long and extend them to 6 minutes or longer', 'Permanent music storage with unlimited storage space'],
                features: ['Up to 5600*3 songs maximum per quarter', '2800*3 generate times per quarter, $0.016/Generate', '100 running jobs at once', 'Unlimited Download MP3 And MP4 Files']
            }
        ];
        const subscriptionPlans = ref(
            new Map([
                ['Basic', basicPlans],
                ['Premium', premiumPlans],
                ['Advanced', advancedPlans]
            ])
        );

        const tabSelected = ref('Basic');
        const selectedPlan = ref(subscriptionPlans.value.get(tabSelected.value));

        const handleTabChanged = (name) => {
            console.log(name);
            console.log(subscriptionPlans.value.get(name));
            tabSelected.value = name;
            selectedPlan.value = subscriptionPlans.value.get(name);
        };
        const tabs = [
            { name: 'Basic', label: 'Basic Plan', shortLabel: 'Basic' },
            { name: 'Premium', label: 'Premium Plan', shortLabel: 'Premium' },
            { name: 'Advanced', label: 'Advanced Plan', shortLabel: 'Advanced' }
        ];
        return { tabs, tabSelected, handleTabChanged, selectedPlan, subscriptionPlans, t };
    }
};
</script>

<style lang="scss" scoped>
.plan {
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-bottom: 32px;

    &-title {
        display: inline-block;
        margin-top: 32px;
        margin-bottom: 32px;
        font-size: 48px;
        font-weight: bold;
        text-align: center;
    }
}

.plan_table {
    //display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 0;
    width: auto;
    gap: 50px;
}

.plan-nav {
    width: 80%;
    margin-bottom: 32px;

    ul.tabs {
        width: 100%;
        margin-top: 0 !important;

        display: flex;
        position: relative;
        background: #7b7a84e3 !important;
        //background: rgb(200 255 21 / 55%) !important;
        //background: #172947 !important;
        border-radius: 4px; // rounded-lg
        padding: 0.25rem; // p-1
        flex-direction: row;

        li.tab {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%;
            position: relative;
            background-color: transparent;
            padding: 0.25rem 0.5rem; // py-1 px-2
            font-size: 1rem; // text-base
            line-height: 1.75rem; // leading-relaxed
            user-select: none; // select-none
            cursor: pointer;
            font-weight: bold;
            color: #1f2937; // text-gray-800
            //color: white; // text-gray-800

            //&.active {
            //    //color: white !important;
            //    color: var(--yellow) !important;
            //
            //    .tab-overlay {
            //        //background-color: #172947 !important;
            //        background-color: #4d648b !important;
            //    }
            //
            //    .tab-content {
            //        color: var(--yellow) !important;
            //    }
            //}

            //&.active {
            //    color: white !important;
            //
            //    .tab-overlay {
            //        background-color: #172947 !important;
            //    }
            //
            //    .tab-content {
            //        color: white !important;
            //    }
            //}

            .tab-content {
                z-index: 20;
                color: inherit;

                .hidden-md {
                    display: none;
                }

                .visible-md {
                    display: inline;
                    -webkit-tap-highlight-color: transparent !important; /* 禁用点击高亮 */
                    outline: none !important; /* 移除轮廓 */
                    user-select: none !important; /* 禁止文本选择 */
                    -webkit-user-select: none !important;
                    -moz-user-select: none !important;
                    -ms-user-select: none !important;
                }
            }

            &:first-of-type .hidden-md {
                @media (min-width: 768px) {
                    display: inline;
                }

                .visible-md {
                    display: none;
                }
            }

            .tab-overlay {
                position: absolute;
                inset: 0;
                z-index: 10;
                height: 100%;
                background-color: #ffffff; // bg-white
                border-radius: 4px; // rounded-md
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // shadow
                transform: none;
                transform-origin: center;
            }
        }
    }
}

:deep {
    .plan_nav .el-tabs__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-basis: 33.33%;

        font-size: 20px;
        text-align: center;

        background: transparent;
        color: white;
        font-weight: bold;

        width: auto;
    }

    .el-tabs__item.is-active {
        color: #c8ff15 !important;

        background: transparent !important;
    }

    .el-tabs__item:hover {
        color: #c8ff15 !important;
        background: transparent !important;
    }
}

/* 移动端 */
@media (max-width: 1000px) {
    .plan {
        display: flex;
        flex-direction: column;
        padding: 5px;
        align-items: center;
        justify-content: center;
        width: auto;

        &-title {
            margin-top: 1rem;
            font-size: 32px;
            font-weight: bold;
            width: 100vw;
        }

        &_table {
            display: flex !important;
            flex-direction: column;
            gap: 2rem;
        }
    }
    .plan-nav {
        width: 100%;
    }

    .tabs {
        margin: 0;
    }
}
</style>
