<template>
    <el-container class="container">
        <el-card class="introduce_card" shadow="never">
            <div class="logo">
                <img :src="logoUrl" />
            </div>
            <div class="introduce">
                <span class="introduce_text">{{ t('SONGAI.AI is the official website of Song AI. Explore SONGAI.AI for the revolutionary Song AI Music Generator V3. Create unique Song AI MP3 songs instantly. Download and enjoy innovative music now!') }}</span>
                <br />
                <br />
                <span class="introduce_text">Copyright © 2024 - All rights reserved</span>
            </div>
        </el-card>

        <el-container class="extra">
            <el-card class="extra_card" shadow="never">
                <div class="extra_title">
                    <span>{{ t('SOLUTIONS') }}</span>
                </div>
                <div class="extra_items">
                    <el-link class="nav-link" href="https://www.songai.ai" target="_blank">SONGAI.AI</el-link>
                </div>
            </el-card>
            <el-card class="extra_card" shadow="never">
                <div class="extra_title">
                    <span>{{ t('LINKS') }}</span>
                </div>
                <div class="extra_items">
                    <el-link class="nav-link" href="https://en.wikipedia.org/wiki/List_of_music_genres_and_styles" target="_blank">
                        {{ t('Documentation') }}
                    </el-link>

                    <el-link class="nav-link" href="https://www.songai.ai/price_plan" target="_blank">{{ t('Pricing') }}</el-link>
                    <el-link class="nav-link" @click="contactUs()">{{
                            t('Contact Us')
                        }}:
                        contact.songai@gmail.com
                    </el-link>
                </div>
            </el-card>
            <el-card class="extra_card" shadow="never">
                <div class="extra_title">
                    <span>{{ t('LEGAL') }}</span>
                </div>
                <div class="extra_items">
                    <router-link class="nav-link" :to="{ name: 'term_of_service' }" replace>{{ t('Terms of services') }}</router-link>
                    <router-link class="nav-link" :to="{ name: 'privacy_policy' }" replace>{{ t('Privacy policy') }}</router-link>
                </div>
            </el-card>
        </el-container>
    </el-container>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
    name: 'footer_about',
    data() {
        return { logoUrl: require('@/assets/logo.svg') };
    },
    setup() {
        const { t } = useI18n();

        return { t };
    },
    methods: {
        contactUs() {
            window.location.href = `mailto:contact.songai@gmail.com`;
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    width: 90%;
    margin: 0 auto;
    padding: 0px;
    background: transparent;
}
.introduce_card {
    /* Auto Layout Vertical */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: transparent;
    padding: 0px;
    gap: 21px;
    border: none;
}

.logo {
    /* logo */

    width: 280px;
    height: 56px;

    background: transparent;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.introduce {
    width: 408px;
    height: auto;
    text-align: left;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.introduce_text {
    width: 408px;
    height: 120px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    /* or 150% */

    color: #d6d6d8;
}

.extra {
    /* Auto Layout Horizontal */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    background: transparent;

    width: auto;
    height: auto;

    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.extra_card {
    /* Auto Layout Vertical */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    padding: 0px;
    gap: 16px;
    border: none;

    width: auto;
    height: auto;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.extra_title {
    /* Page Menus */

    width: auto;
    height: auto;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    padding-bottom: 20px;

    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.extra_items {
    /* Auto Layout Vertical */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-size: 10px;
    gap: 14px;
    text-align: left;

    width: auto;
    height: auto;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    .is-link {
        width: 100%;
    }
}

.nav-link {
    color: #d6d6d8;
    font-size: 16px;
    text-decoration: none;
}

/* 定义激活状态下的链接样式 */
.nav-link.active {
    color: #b6ff52;
}

/* 定义鼠标悬停时的链接样式 */
.nav-link:hover {
    position: relative;
    color: #b6ff52;

    .el-link.el-link--default:after {
        border-color: #b6ff52 !important;
    }

    &::after {
        border-bottom: 1px solid #b6ff52 !important;
        bottom: 0;
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        right: 0;
    }
}

/* 移动端 */
@media (max-width: 1000px) {
    .container {
        flex-direction: column;
        margin-top: 20px;

        .introduce {
            width: 100% !important;

            &_text {
                display: inline-block;
                width: 100% !important;
                text-align: center;
            }
        }

        .introduce_card {
            text-align: center;

            :deep(.el-card__body) {
                .logo {
                    width: 100%;

                    img {
                        margin-right: -20px !important;
                    }
                }

                .introduce_text {
                    margin-bottom: 32px !important;
                }
            }
        }

        .extra {
            margin-top: -100px;
            margin-bottom: 60px;
            flex-direction: column;
            gap: 3rem;

            &_card {
                width: 100% !important;

                :deep(.el-card__body) {
                    width: 100% !important;
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                    padding: 0 !important;

                    .extra_title {
                        text-align: center !important;
                        padding-bottom: 16px;
                    }

                    .extra_items {
                        text-align: center !important;
                        gap: 8px;

                        .el-link {
                            width: 100%;
                        }

                        .is-left {
                            width: 100%;
                        }

                        .nav-link {
                            width: auto;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}
</style>
