
import axios from 'axios';
//const API_URL = 'http://8.218.87.240';
const API_URL = 'https://api.songai.ai';


export const fetchData = async (uri_path, fetchParams) => {
  try {
    console.log("fetchData uri_path:", uri_path, " fetchParams:", fetchParams);
   return await axios.get(`${API_URL}/api/` + uri_path, { params: fetchParams });

  } catch (err) {
    console.log("fetchData uri_path:" + uri_path + " failed. " + err);
    let resErr = {
      code: 400,
      message : "api error"
    };
    return  resErr;
  }
  
};

export const sendData = async (uri_path, payload) => {
  try {
    return await axios.post(`${API_URL}/api/` + uri_path, payload);

  } catch (err) {
     console.log("sendData uri_path:" + uri_path + " failed. " + err);
    let resErr = {
      status: 400,
      message : "api error"
    };
    return  resErr;
  }
};


export const apiLogin = (payload) => {
  return  sendData('account/login', payload);
}


export const apiSongGen = (payload) => {
  return  sendData('song/gen', payload);
}

export const apiSongItems = (payload) => {
  return  sendData('song/items', payload);
}


export const apiRandomLyricsGen = (lyricsPrompt) => {
  var request = {
    seqId: new Date().getTime(),
    metadata: {
      prompt: lyricsPrompt,
      lyricGen: true
    }
  };
  return  sendData('song/gen', request);
}

export const apiLyricsTaskQuery = (taskId) => {
  var payload = {
    seqId: new Date().getTime(),
    metadata: {
      sunoLyricGenTask: taskId,
      lyricGen: true
    }
  };
  return  sendData('song/task/status', payload);
}

export const apiSongTaskQuery = (payload) => {
  return  sendData('song/task/status', payload);
}


export const apiSongList = (params) => {
  return  fetchData('song/list', params);
}

export const apiSongDelete = (payload) => {
  return  sendData('song/delete', payload);
}
export const apiSuscripeOrders = (fetchParams) => {
  return  fetchData('subscribe/orders', fetchParams);
}


export const apiSuscripeRefunds = (fetchParams) => {
  return  fetchData('subscribe/refunds', fetchParams);
}

export const apiCreateSubscription = (payload) => {
  return  sendData('subscribe/create_subscription', payload);
}

export const apiCancelSubscription = (payload) => {
  return  sendData('subscribe/cancel', payload);
}

export const apiResumeSubscription = (payload) => {
  return  sendData('subscribe/resume', payload);
}

export const apiAccountRights = (fetchParams) => {
  return  fetchData('account/rights', fetchParams);
}

export const apiShareViewClip = (fetchParams) => {
  return  fetchData('share/view', fetchParams);
}

export const apiSubscriptionEventReportInfo= (fetchParams) => {
  return  fetchData('subscribe/event_reportinfo', fetchParams);
}



